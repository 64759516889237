import { ASSETS_URL } from "@/services/base.js";

export default {
  name: "metro-items",
  components: {},
  props: [],
  data() {
    return {
      baseUrl: ASSETS_URL
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
